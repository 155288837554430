export const SUBSCRIPTION_NAMES = {
  ESSENTIAL: 'essential',
  PRO: 'pro',
  ELITE: 'elite',
};

export const LAYOUT_TYPE_HEADING = {
  layout1: 'Layout 1',
  layout2: 'Layout 2',
  layout3: 'Layout 3',
  layout4: 'Layout 4',
};

export const CONTENT_POSITION_TYPE = {
  OVERLAY: 'overlay',
  BELOW: 'below',
};

export const LIST_TYPE = {
  COLLECTIONS: 'collections',
  CATEGORY: 'category',
};

export const LIST_ITEM_HEADING = {
  collection: 'Collections',
  category: 'Categories',
};

export const CARD_SHAPE = 'cardShape';
export const Y_ALIGNMENT = 'yAlignment';
export const IMAGE_OVERLAY_TOP = ['top', 'middle', 'bottom'];

export const DEFAULT_COLLECTION_CARD_IMAGE =
  'https://cdn.dotpe.in/longtail/themes/8073865/JXSaayl0.webp';

export const POSITION_DATA = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};

export const ASPECT_RATIO_MAP = {
  square: '1:1',
  portraitTall: '2:3',
  portraitShort: '3:4',
  landscape: '3:2',
  landscapeWide: '4:3',
  circle: '1:1',
  blob: '1:1',
  arch: '3:4',
} as const;

export const LAYOUT_STYLE = [
  {
    text: 'Grid',
    value: 'grid',
    itemLevelClass: 'tw-w-[50%]',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Carousel',
    value: 'carousel',
    itemLevelClass: 'tw-w-[50%]',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

export const DESCRIPTION_POSITION = [
  {
    text: 'Top',
    value: 'top',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Below',
    value: 'below',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

export const CARD_SHAPES_TO_CENTER_ALIGN = ['circle', 'blob'];

export const CARD_SHAPES_NAMES = {
  SQUARE: 'square',
  PORTRAIT_TALL: 'portraitTall',
  PORTRAIT_SHORT: 'portraitShort',
  LANDSCAPE: 'landscape',
  LANDSCAPE_WIDE: 'landscapeWide',
  CIRCLE: 'circle',
  BLOB: 'blob',
  ARCH: 'arch',
};

export const TEXT_SIZE_DROPDOWN = [
  {
    text: 'Extra small (12px)',
    value: 'extraSmall',
    itemLevelClass: { fontSize: '12px !important' },
  },
  {
    text: 'Small (14px)',
    value: 'small',
    itemLevelClass: { fontSize: '14px !important' },
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Medium (16px)',
    value: 'medium',
    itemLevelClass: { fontSize: '16px !important' },
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Large (18px)',
    value: 'large',
    itemLevelClass: { fontSize: '18px !important' },
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Extra Large (20px)',
    value: 'extraLarge',
    itemLevelClass: { fontSize: '20px !important' },
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

export const TEXT_CASE = [
  {
    text: 'Default',
    value: 'none',
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'Aa',
    value: 'capitalize',
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'AA',
    value: 'uppercase',
    itemLevelClass: 'tw-w-[33%]',
  },
];

export const TEXT_THICKNESS = [
  {
    text: 'Thin',
    value: 'thin',
    itemLevelClass: { fontWeight: '300 !important' },
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Regular',
    value: 'regular',
    itemLevelClass: { fontWeight: '400 !important' },
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Medium',
    value: 'medium',
    itemLevelClass: { fontWeight: '500 !important' },
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Semi-bold',
    value: 'semiBold',
    itemLevelClass: { fontWeight: '600 !important' },
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Bold',
    value: 'bold',
    itemLevelClass: { fontWeight: '700 !important' },
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];
